<template>
    <transition name="fade">
        <div class="modal-wrapper" v-show="visible">
            <div class="modal-overlay" @click="$emit('close')"></div>
            <div class="modal-content">
                <slot></slot>
                <div class="modal-close">
                    <svg @click="$emit('close')" t="1678002312668" class="close-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2758"><path d="M704.2 732.9c-6.9 0-13.8-2.6-19.1-7.9L295.2 335.1c-10.5-10.5-10.5-27.6 0-38.2 10.5-10.5 27.6-10.5 38.1 0l389.9 389.9c10.5 10.5 10.5 27.6 0 38.1-5.2 5.4-12.1 8-19 8z" fill="#ffffff" p-id="2759"></path><path d="M314.3 732.9c-6.9 0-13.8-2.6-19.1-7.9-10.5-10.5-10.5-27.6 0-38.1L685.1 297c10.5-10.5 27.6-10.5 38.1 0s10.5 27.6 0 38.2L333.3 725c-5.2 5.3-12.1 7.9-19 7.9z" fill="#ffffff" p-id="2760"></path><path d="M509.2 959.6c-246.9 0-447.8-200.9-447.8-447.8S262.3 64 509.2 64 957 264.8 957 511.8c0 246.9-200.8 447.8-447.8 447.8z m0-841.7c-217.2 0-393.9 176.7-393.9 393.9S292 905.7 509.2 905.7 903.1 729 903.1 511.8 726.4 117.9 509.2 117.9z" fill="#ffffff" p-id="2761"></path></svg>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>
    .modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    .modal-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 100%;
        height: 90%;
        /* overflow-y: auto; */
        z-index: 1;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.4s ease-in-out;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .modal-close {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .close-icon {
        width: 22px;
        height: 22px;
        cursor: pointer;
    }
</style>
