<template>
  <div class="Intro" :style="introBg">
    <div class="shadow-box">
        <div class="eng-title">3Q BASE</div>
        <div class="chs-title-box">
            <div class="line left-line"></div>
            <div class="chs-title">三驱基地</div>
            <div class="line right-line"></div>
        </div>
        <div class="intro-text">
            <p>2019年7月3日，3QLAB诞生于浙江大学紫金港校区的北街咖啡馆。</p>
            <p>目前，3QLAB已经发展成为一个由科学、艺术和技术领域专业人士</p>
            <p>组成的DAC（Decentralized Autonomous Community，去中心</p>
            <p>化自治共同体）。我们立足浙江大学，放眼全球，基于区块链技术，</p>
            <p>聚焦跨学科跨领域协作，以金融、科学与艺术三驾齐驱，探索未来的</p>
            <p>无限可能，拓展人类的认知边界。</p>
        </div>
        <!-- <img class="intro-logo" src="/images/3qlab_logo_transparent.png"/> -->
        <img class="intro-logo" :src="image_url + '/3qlab_logo.png'"/>
    </div>
  </div>
</template>

<script>
import {image_url } from '../../../const';

export default {
  name: "base_intro",
  data() {
    return {
      image_url: image_url,
      introBg: {
        background: 'url(' + image_url + '/Base/intro_bg.png)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .Intro {
    width: 1200px;
    margin: 0 auto;
    // height: 576px;
    padding: 70px 0;
  }
  
  .shadow-box {
    margin: 0 auto;
    width: 50%;
    background: rgba(0, 0, 0, 0.55);
    text-align: center;
    padding: 50px 0;
  }

  .eng-title {
    width: 100%;
    font-size: 19px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
    line-height: 30px;
  }

  .chs-title-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chs-title {
    font-size: 36px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
  }

  .line {
    width: 30%;
    height: 1px;
    border: 1px solid;
    margin: 0 20px;
  }

  .left-line {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .right-line {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); 
  }

  .intro-text {
    text-align: center;
    margin-top: 30px;
    padding-left: 5px;
    p {
        font-size: 14px;
        font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 17px;
    }
  }

  .intro-logo {
    margin-top: 30px;
    width: 250px;
  }
</style>