<template>
  <div class="home">
    <Header></Header>
    <Intro></Intro>
    <TimeRing></TimeRing>
    <Archives></Archives>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Intro from './com/intro';
import TimeRing from './com/time_ring';
import Archives from './com/archives';
import Footer from '@/components/Footer';

export default {
  data() {
    return {
      listheight:''
    };
  },
  components: {
    Header,
    Intro,
    TimeRing,
    Archives,
    Footer
  },
  mounted() {
    
  },
  methods: {
    init() {
      
    }
  }

}
</script>

<style scoped lang="scss">

.home {
  background-color: #000;
  width: 100%;
}

</style>