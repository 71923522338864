import request from '../utils/request';

//初始化数据
export const getEvents = () => {
    return request({
        url: process.env.VUE_APP_URL + 'Base/getEvents',
        method: 'get',
        params: {}
    });
};

export const getArchives = () => {
    return request({
        url: process.env.VUE_APP_URL + 'Base/getArchives',
        method: 'get',
        params: {}
    });
};