export const year_color = {
    "2019": "#F5C001",
    "2020": "#FF93B5",
    "2021": "#27FFFB",
    "2022": "#8AFF28",
    "2023": "#ff43d8",
    "2024": "#00dd43",
    "2025": "#ff9f24",
}

export const events_info = [
    {
        time: "2019/7/3",
        event: "3Q LAB诞生",
        location: "杭州",
        detail: "浙江大学紫金港校区的北街咖啡内，创始人一起讨论出3Q LAB的雏形、前景和未来。",
        link: ""
    }, 
    {
        time: "2019/9/26",
        event: "三驱杭州注册",
        location: "杭州",
        detail: "三驱科技（杭州）有限公司正式注册，初定主要面向科研社交。",
        link: "https://mp.weixin.qq.com/s/qP1E2dE0HpUXbEf6fHmkZQ"
    },
    {
        time: "2020/1/4",
        event: "3Q LAB app开放内测",
        location: "线上",
        detail: "主打科研社交平台，科研、艺术、技术三大圈子，内测三个月积累近200名元老用户。",
        link: "https://mp.weixin.qq.com/s/qP1E2dE0HpUXbEf6fHmkZQ"
    }, 
    {
        time: "2020/3/1",
        event: "与之江实验室课题合作",
        location: "杭州",
        detail: "《人工智能对社会和环境治理的影响》研究课题合作。",
        link: ""
    }, 
    {
        time: "2020/4/20",
        event: "3Q LAB app全平台上线",
        location: "线上",
        detail: "3Q名片、科研集市等新增功能备受瞩目。",
        link: ""
    }, 
    {
        time: "2020/5/1",
        event: "3Q专属微信表情包上线",
        location: "线上",
        detail: "“3QLab科学与艺术”系列在微信表情包平台上线。",
        link: ""
    }, 
    {
        time: "2020/5",
        event: "比赛获奖",
        location: "北京",
        detail: "OPPO Top 高校 5G大赛决赛入围奖。",
        link: ""
    }, 
    {
        time: "2020/8",
        event: "比赛获奖",
        location: "嘉兴",
        detail: "第四届“红船杯”嘉兴全球创赛总决赛三等奖。",
        link: ""
    },
    {
        time: "2020/10",
        event: "比赛获奖",
        location: "福州",
        detail: "第十届海峡两岸信息服务创新大赛三等奖。",
        link: ""
    }, 
    {
        time: "2020/11",
        event: "比赛获奖",
        location: "广州",
        detail: "第六届中国国际互联网+创赛国赛金奖。",
        link: ""
    }, 
    {
        time: "2020/11/16",
        event: "三驱嘉兴落地",
        location: "嘉兴",
        detail: "三驱科技（杭州）有限公司落地嘉兴天鹅湖畔，侧重运营数字经济。",
        link: ""
    }, 
    {
        time: "2020/11/16",
        event: "入驻上海张江",
        location: "上海",
        detail: "入驻上海张江的中国电信天翼孵化器，加强5G+合作。",
        link: ""
    }, 
    {
        time: "2020/11/16",
        event: "落地苏州",
        location: "苏州",
        detail: "入驻网易苏州孵化园。",
        link: ""
    }, 
    {
        time: "2021/3/16",
        event: "三驱上链功能上线",
        location: "线上",
        detail: "存证功能上线，用户可将自己的idea存至区块链并实时同步至浙江省知识产权研究中心和杭州互联网法院。",
        link: "https://mp.weixin.qq.com/s/OTdg5Z3APOQAixb0-1psDg"
    },
    {
        time: "2021/3/21",
        event: "万人添书开启众创文学创作",
        location: "杭州",
        detail: "以《回到七天前》为主题进行内测，吸引22支队伍参与，七天创作18万字。",
        link: ""
    }, 
    {
        time: "2021/4/20",
        event: "与马云公益基金会合作",
        location: "杭州",
        detail: "于杭州西溪地水资源研究中心共同探讨服务乡村建设、关注女性平等、非洲创业、环境治理等问题。",
        link: ""
    },  
    {
        time: "2021/6/1",
        event: "万人添书第二季",
        location: "杭州",
        detail: "以《校园档案》为主题，吸引400余人参与，十四天文字共计71万字。",
        link: ""
    }, 
    {
        time: "2021/6",
        event: "与“e签宝”达成服务合作",
        location: "杭州",
        detail: "与杭州天谷信息科技有限公司（“e签宝”）合作助力3Q首创贡献值系统开发，以评价、认证、奖励个人及团队的贡献度，为DAO组织构架打下基础。",
        link: ""
    },  
    {
        time: "2021/7",
        event: "中华蜜蜂别墅认养计划",
        location: "绵阳",
        detail: "与北京林业大学张玉波博士一起助力当地养蜂业，在保护好当地绿水青山的同时，创造出金山银山，更好达到人与自然和谐共生的目的。",
        link: ""
    }, 
    {
        time: "2021/8",
        event: "天使轮融资数百万元",
        location: "杭州",
        detail: "三驱科技（杭州）获得数百万融资，助力3Q更好发展。",
        link: ""
    },
    {
        time: "2021/9/1",
        event: "添书文化成立",
        location: "杭州",
        detail: "添书文化（杭州）有限公司正式成立，专注群体创作。",
        link: ""
    }, 
    {
        time: "2021/10",
        event: "成为杭州未来公共安全研究院主任理事单位",
        location: "杭州",
        detail: "未来双方将在区块链国家治理、实验室公共安全等前沿领域发挥服务功能，增强社会效益。",
        link: ""
    },
    {
        time: "2021/10/15",
        event: "荣获教育部创新创业英才奖",
        location: "南昌",
        detail: "“三大奖”是新中国成立以来专门针对高等教育教学领域的最大重奖，三驱科技创始人钱文鑫凭借优秀的创业精神与创新实践能力，获得创新创业英才奖。",
        link: ""
    }, 
    {
        time: "2021/12/30",
        event: "万人添画·失控蚂蚁",
        location: "杭州",
        detail: "联手艺术大师王小松，推出千人共创的NFT画作《万人添画·失控蚂蚁》，5天共计2021幅画作构成最终作品。",
        link: ""
    },
    {
        time: "2022/5",
        event: "万人添画献礼浙大125周年校庆",
        location: "杭州",
        detail: "献礼浙大125周年校庆，完成1250个坐标构成的《万人添画·灿若星辰》NFT作品，共创绘画模式实践落地。",
        link: ""
    }, 
    {
        time: "2022/6/16",
        event: "3QLAB新加坡公司注册成立",
        location: "新加坡",
        detail: "放眼世界、全球视野一直是我们公司战略的重要组成部分，新加坡的Web3.0发展如火如荼，3Q也将顺风而行。",
        link: ""
    },
    {
        time: "2022/6",
        event: "发表论文",
        location: "",
        detail: "和北大第三医院在JCMM杂志联合发文。",
        link: ""
    }, 
    {
        time: "2022/9",
        event: "发表论文",
        location: "",
        detail: "和北大医学部合作发表影响因子13.9的论文。",
        link: ""
    },
    {
        time: "2022/9/25",
        event: "3QLAB战略投资盛天传媒",
        location: "北京",
        detail: "完成对《天道》出品公司-盛天传媒的战略投资，成为其第一大股东，将运用DAC模式对其债务重组及赋能。",
        link: "https://mp.weixin.qq.com/s/N8faND_BWKqh3VlOA3cgLA"
    }, 
    {
        time: "2022/10",
        event: "万人添画·杭州万象城NFT展",
        location: "杭州",
        detail: "国庆黄金周，携手华润·杭州万象城举办NFT线下作品展览、作画活动—万人添象，吸引数万人打卡参与。",
        link: ""
    },
    {
        time: "2022/11",
        event: "3Q城市巡游计划开启",
        location: "中国长三角",
        detail: "3QNFT：艺术+金融+科技，借助传统线下资产和空间，在各城市巡展，超低成本运营。",
        link: ""
    }, 
    {
        time: "2022/10",
        event: "3Q LAB 香港公司注册成立",
        location: "中国香港",
        detail: "3QLAB将以DAC的模式，持续、积极地投入此次香港Web3.0发展浪潮中，由香港走向全球。",
        link: "https://mp.weixin.qq.com/s/H_KNfQKU93Na0OzrYMRhGA"
    },     
]

export const archives_info = [
    {
        sub_title: "9项",
        title: "软件著作权",
        cover_img: "/Base/software/0.jpg",
        content_imgs: [
            "/Base/software/0.jpg",
            "/Base/software/1.jpg",
            "/Base/software/2.jpg",
            "/Base/software/3.jpg",
            "/Base/software/4.jpg",
            "/Base/software/5.jpg",
            "/Base/software/6.jpg",
            "/Base/software/7.jpg",
            "/Base/software/8.jpg",
        ]
    },
    {
        sub_title: "金奖",
        title: "“创青春·挑战杯”+“互联网+”大学生创新创业大赛双料金奖",
        cover_img: "/Base/gold_prize_merge.png",
        content_imgs: []
    },
    {
        sub_title: "科创企业",
        title: "挂牌浙江省股权交易中心",
        cover_img: "/Base/enterprise.png",
        content_imgs: []
    },
    {
        sub_title: "英才奖",
        title: "创始人钱文鑫荣获2021年第三届教育部创新创业英才奖",
        cover_img: "/Base/elite_cover.png",
        content_imgs: ["/Base/elite.png"]
    },
    {
        sub_title: "大众创业",
        title: "2020-2021连续两年入选国务院全国大众创业万众创新活动周项目",
        cover_img: "/Base/startup_cover.png",
        content_imgs: ["/Base/startup.png"]
    },
    {
        sub_title: "数字化科研",
        title: "3QLAB艺术品数字化科研项目获省文旅厅立项",
        cover_img: "/Base/digital.png",
        content_imgs: ["/Base/digital.png"]
    },
    {
        sub_title: "SCI论文",
        title: "与北大医学部等联合发表SCI论文两篇",
        cover_img: "/Base/paper_0.png",
        content_imgs: [
            "/Base/paper_0.png",
            "/Base/paper_1.png"
        ]
    },
]

export default {
    events_info,
    year_color,
    archives_info
}