<template>
  <div class="TimeRing" scroll="no">
    <div class="eng-title">SINGULARITY TREE RINGS</div>
    <div class="title-box">
        <div class="chs-title">奇点年轮</div>
        <div class="title-line"></div>
    </div>
    <div class="timeline-box" ref="timeBox">
      <div class="timeline-row">
        <template v-for="(item, idx) in events_info">
          <div class="timeline-item" :style="{left: timeOffset + 'px'}" :key="'time_' + idx">
            <div>{{item.event_time}}</div>
            <img :style="{opacity: idx == selected_events ? 1 : 0}" ref="timeMarkers" class="timeline-marker" :src="image_url + '/Base/time_marker.jpg'" />
          </div>
        </template>
      </div>
      <div class="timeline-line"></div>
    </div>
    <div class="events-box" ref="eventsBox">
      <div class="events-row" >
        <template v-for="(item, idx) in events_info">
          <div class="events-item" @mouseover="changeEvents(0, idx)" ref="eventsItem" :class="{'selected-item': idx == selected_events}" :style="{zIndex: 999 - Math.abs(idx - selected_events)}" :key="'events_' + idx">
            <div class="events-item-title">
              <div v-if="idx == selected_events">
                <div class="title-time" :style="{color: year_color[item.event_time.split('/')[0]]}">
                  {{item.event_time.replace(/\//g, '\.')}} 
                </div>
                <div class="title-event">
                  {{item.event_name}}
                </div>
              </div>
            </div>
            <div class="events-item-content">
              <div v-if="idx == selected_events" style="padding: 15px 13px;">
                <div class="content-detail">
                  {{item.detail}}
                </div>
                <div class="content-location" v-if="item.location">
                  地点：{{item.location}}
                </div>
                <div class="content-link">
                  {{item.link}}
                </div>
              </div>
              <div v-else :class="idx < selected_events ? 'content-time time-left' : 'content-time time-right'" :style="{color: year_color[item.event_time.split('/')[0]]}">
                {{item.event_time.replace(/\//g, '\.')}}
              </div>
            </div>
          </div>
        </template> 
      </div>
      <div class="events-box-mask"></div>
    </div>
    <div class="event-select-box">
      <div class="circle" @click="changeEvents(-1, -1)">&lt;</div>
      <div class="circle" @click="changeEvents(1, -1)">></div>
    </div>
  </div>
</template>

<script>
import { year_color } from '../const';
import {image_url } from '../../../const';
import { getEvents } from "@/api/Base";

export default {
  name: "base_time_ring",
  data() {
    return {
        events_info: [],
        year_color: year_color,
        selected_events: 0,
        timeOffset: 0,
        image_url: image_url
    };
  },
  mounted() {
    this.init();
  },
  watch: { 
    selected_events: function() {
      this.changeItemWidth();
    },
  },
  computed: {
    
  },
  methods: {
    init() {
      getEvents().then(res => {
        this.events_info = res.data;

        let that = this;
        let interval = setInterval(() => {
          if (that.$refs.timeMarkers) {
            that.changeItemWidth();
            clearInterval(interval);
          }
        }, 200);
        
      });
    },
    changeEvents(offset, idx) {
      let targetEvents;
      if (offset < 0) {
        targetEvents = Math.max(this.selected_events + offset, 0); 
      } else if (offset > 0) {
        targetEvents = Math.min(this.selected_events + offset, this.events_info.length - 1);
      } else {
        targetEvents = idx;
      }

      this.selected_events = targetEvents;
    },
    changeItemWidth() {
      if (this.events_info.length == 0) {
        return ;
      }
      // cur items
      let markerLeft = this.$refs.timeMarkers[this.selected_events].getBoundingClientRect().left;
      // markerLeft += nextOffset;
      let boxLeft = this.$refs.eventsBox.getBoundingClientRect().left;
      let boxRight = this.$refs.eventsBox.getBoundingClientRect().right;
     
      let curEvent = this.selected_events;

      // border
      let nextOffset = 0;
      if (markerLeft + 200 > boxRight && curEvent < this.events_info.length - 1) {
        let nextMarkerLeft = this.$refs.timeMarkers[curEvent + 1].getBoundingClientRect().left; 
        nextOffset -= (nextMarkerLeft - markerLeft);
      }

      if (markerLeft - 200 < boxLeft && this.timeOffset < 0) {
        if (curEvent > 1) {
          let prevMarkerLeft = this.$refs.timeMarkers[curEvent - 1].getBoundingClientRect().left; 
          nextOffset += (markerLeft - prevMarkerLeft);
        } else {
          nextOffset += 70;
        }
        
        //nextOffset += 40;
      }

      this.timeOffset = Math.min(this.timeOffset + nextOffset, 0);
      
      this.$refs.eventsItem[this.selected_events].style.left = markerLeft - boxLeft - 160 + nextOffset + 'px' ; 

      // other items
      let cur = this.selected_events;
      let offsetSum = markerLeft - boxLeft + nextOffset - 200;
      for (let i = cur - 1; i >= 0; i--) {
        let item = this.$refs.eventsItem[i];
        offsetSum -= 40;
        if (cur - i < 8) {
          let diff = 8 - cur + i; 
          offsetSum -= diff * 8;
        }
        item.style.left = offsetSum + 'px';
      }

      offsetSum = markerLeft - boxLeft + nextOffset - 130;
      for (let i = cur + 1; i < this.events_info.length; i++) {
        let item = this.$refs.eventsItem[i];
        offsetSum += 40;
        if (i - cur < 8) {
          let diff = 8 - i + cur; 
          offsetSum += diff * 15;
        }
        item.style.left = offsetSum + 'px';
      }

    }
  }
}
</script>

<style scoped lang="scss">
  .TimeRing {
    width: 1200px;
    height: 570px;
    margin: 0 auto 20px auto;
    overflow-x: hidden;
    overflow-y: initial;
  }
  
  .title-box {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 80%;
  }
  
  .eng-title {
    width: 80%;
    margin: 0 auto;
    font-size: 19px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
    line-height: 25px;
  }

  .chs-title {
    width: 30%;
    font-size: 36px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
  }

  .title-line {
    width: 65%;
    height: 1px;
    border: 1px solid;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); 
  }

  .timeline-box {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }

  .timeline-row {
    overflow: hidden;
    width: 3000px;
    margin: 50px auto 10px auto;
    position: relative;
    left: 0;
    top: 0;
  }

  .timeline-item {
    float: left;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #A3A3A3;
    font-size: 9px;
    margin: 0 10px;
    position: relative;
    text-align: center;
    left: 0;
    transition: all 0.7s ease 0s
  }

  .timeline-line {
    width: 1500px;
    height: 1px;
    border: 1px solid;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    position: absolute;
    bottom: 10px;
    z-index: 0;
  }

  .timeline-marker {
    width: 15px;
    height: 15px;
    margin-top: 10px;
    position: relative;
    z-index: 1;
  }

  .events-box {
    width: 98%;
    margin: 0 auto;
    position: relative;
  }

  .events-row {
    width: 10000px;
    // margin: 50px auto;
    margin-top: 50px;
    margin-left: 50px;
    height: 300px;
    position: relative;
    left: 0;
    top: 0;
  }

  .selected-item {
    transform: scale(1.2);
  }

  .item-shadow {
    opacity: 0.65;
  }

  .events-item {
    float: left;
    position: absolute;
    // margin-left: -50px;
    box-shadow: 0px 1px 18px 0px rgba(0,0,0,0.5);
    transition: all 0.7s ease 0s
  }

  .events-item-title {
    width: 240px;
    height: 80px;
    background: linear-gradient(270deg, #3D75E4 0%, #7C5FD7 100%);
    padding: 14.5px 13px;
    box-sizing: border-box;

    .title-time {
      font-size: 14px;
      font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
      font-weight: bold;
      color: #FFFFFF;
    }

    .title-event {
      font-size: 16px;
      font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  .events-item-content {
    width: 240px;
    height: 160px;
    background: #FFFFFF;
    position: relative;

    .content-time {
      font-size: 20px;
      width: 90px;
      height: 30px;
      text-align: center;
      font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
      font-weight: bold;
      color: #000000;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      position: absolute;
      bottom: 65px;
      
    }

    .time-left {
      left: -30px;
    }

    .time-right {
      right: -30px;
    }

    .content-detail {
      width: 100%;
      font-size: 13px;
      font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
      font-weight: 500;
      color: #000000;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .content-location {
      font-size: 12px;
      font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
      color: #A3A3A3;
      line-height: 25px;
    }

    .content-link {
      font-size: 12px;
      font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
      color: #A3A3A3;
      line-height: 22px;
      word-break: break-all;
    }
  }

  .events-box-mask {
    width: 3000px;
    height: 300px;
    position: absolute;
    left: -200px;
    bottom: 30px;
    background: rgba(0,0,0,0.5);
    z-index: 998;
    pointer-events: none;
  }

  .event-select-box {
    width: 80%;
    margin: 0 auto;
  }

  .circle {
    float: left;
    width: 35px;
    height: 35px;
    margin-right: 15px;
    border-radius: 28px;
    border: 1px solid #FFFFFF;
    font-size: 15px;
    color: #FFFFFF;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }
</style>