<template>
  <div class="Archives" id="achives">
    <div class="eng-title">FLUCTUATION ARCHIVES</div>
    <div class="title-box">
      <div class="chs-title">波动档案</div>
      <div class="title-line"></div>
    </div>
    <div class="archives-box" v-if="archives_info.length > 0">
      <div class="swiper-container" id="archives-swiper">
        <div class="swiper-wrapper">
          <div v-for="page in page_num" class="swiper-slide" :key="'archives_slide' + page">
            <div v-for="i in 4" :key="'archives_row' + i">
              <div class="archives-row">
                  <div class="archives-item" v-for="j in 2" :key="'archives_item' + i * 2 + j">
                      <div v-if="num_per_page * (page - 1) + (i - 1) * 2 + j - 1 < archives_info.length">
                        <div class="item-header">
                          <div class="sub-title">
                            {{archives_info[num_per_page * (page - 1) + (i - 1) * 2 + j - 1].sub_title}}
                          </div>
                          <div class="main-title">
                            {{archives_info[num_per_page * (page - 1) + (i - 1) * 2 + j - 1].title}}
                          </div>
                        </div>
                        <div class="item-num">{{9 > num_per_page * (page - 1) + (i - 1) * 2 + j - 1 ? "0" + (num_per_page * (page - 1) + (i - 1) * 2 + j) : num_per_page * (page - 1) + (i - 1) * 2 + j}}</div>
                        <div class="item-content">
                          <img class="content-cover" :src="image_url + archives_info[num_per_page * (page - 1) + (i - 1) * 2 + j - 1].cover_img"/>
                        </div>
                        <div v-if="archives_info[num_per_page * (page - 1) + (i - 1) * 2 + j - 1].content_imgs.length > 0" class="item-more" @click="openArchivesModal(num_per_page * (page - 1) + (i - 1) * 2 + j - 1)">→</div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow-box">
      <div class="archives-prev archives-arrow" @click="getArchivesPage()">></div>
      <div class="archives-next archives-arrow" @click="getArchivesPage()">></div>
    </div>
    
    <modal :visible="modalShow" @close="closeModal()" v-if="archives_info.length > 0">
      <div class="item-header">
        <div class="sub-title">
          {{archives_info[selected_archives].sub_title}}
        </div>
        <div class="main-title">
          {{archives_info[selected_archives].title}}
        </div>
      </div>
      <div class="item-num">{{9 > selected_archives ? "0" + (selected_archives + 1) : selected_archives + 1}}</div>
      <div class="item-content-detail">
        <div class="item-image">
          <img :src="image_url + archives_info[selected_archives].content_imgs[cur_detail_page - 1]" style="object-fit: scale-down; max-height: 90%; max-width: 90%">
        </div>
        <div class="item-gallery">
            <div class="swiper-container gallery-swiper-container" id="gallery-swiper">
              <div class="swiper-wrapper gallery-swiper-wrapper">
                <div class="swiper-slide gallery-swiper-slide" v-for="(item, index) in archives_info[selected_archives].content_imgs" :key="'slide' + index">
                  <img :src="image_url + item" @click="changePage(index + 1)"/>
                </div>
              </div>
              <div class="swiper-mask"></div>
            </div>
            <div class="pages-icon">
              <div v-for="i in archives_info[selected_archives].content_imgs.length" :key="i">
                <div @click="changePage(i)" class="page-circle" :class="{'selected-circle': cur_detail_page == i}"></div>
              </div>
            </div>
        </div>
      </div>

    </modal>
  </div>
</template>

<script>
import 'animate.css';
import {image_url } from '../../../const';
import { getArchives } from "@/api/Base";
import 'viewerjs/dist/viewer.css'
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";

import Modal from "./modal";

export default {
  name: "base_archives",
  components: {
    Modal
  },
  data() {
    return {
        archives_info: [],
        image_url: image_url,
        cur_archives_page: 1,
        cur_detail_page: 1,
        page: 1,
        num_per_page: 8,
        selected_archives: 0,
        gallery_swiper: null,
        archives_swiper: null,
        modalShow: false,
    };
  },
  computed: {
    page_num: function() {
      return Math.floor((this.archives_info.length + this.num_per_page - 1) / this.num_per_page);
    }
  },
  mounted() {
    getArchives().then(res => {
      this.archives_info = res.data;

      let that = this;
      let interval = setInterval(() => {
        if (that.archives_info.length > 0) {
          that.archives_swiper = new Swiper("#archives-swiper", {
            direction: 'vertical',
            prevButton: '.archives-prev',
            nextButton: '.archives-next',
          });
          clearInterval(interval);
        }
      }, 200);
    })
  },
  methods: {
    openArchivesModal(i) {
      this.selected_archives = i;
      this.cur_detail_page = 1;
      this.modalShow = true;
      let that = this;
      setTimeout(() => {
          that.gallery_swiper = new Swiper("#gallery-swiper", {
            slidesPerView: 4,
            // slidesOffsetAfter: 100
            spaceBetween: 20
          });
      }, 200);
    },

    changePage(i) {
      this.cur_detail_page = i;
      this.gallery_swiper.slideTo(i - 1);
    },

    closeModal() {
      this.modalShow = false;
    },

    getArchivesPage() {
      document.getElementById("achives") .scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style scoped lang="scss">
  .Archives {
    width: 1200px;
    margin: 0 auto 20px auto;
  }
  
  .title-box {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 80%;
  }
  
  .eng-title {
    width: 80%;
    margin: 0 auto;
    font-size: 19px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
    line-height: 25px;
  }

  .chs-title {
    width: 30%;
    font-size: 36px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
  }

  .title-line {
    width: 65%;
    height: 1px;
    border: 1px solid;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .archives-box {
    width: 1200px;
    margin: 70px auto 30px auto;
    height: 1400px;
  }

  #archives-swiper {
    height: 100%;
  }

  .archives-row {
    width: 90%;
    display: flex;
    margin: 0 auto 50px auto;
    justify-content: space-between;
  }

  .archives-item {
    width: 48%;
    position: relative;
  }

  .item-header {
    background: linear-gradient(180deg, #3E75E4 0%, #7C5FD7 100%);
    height: 90px;
    text-align: center;
    padding: 20px 23%;
  }

  .sub-title {
    font-size: 12px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 20px;
  }

  .main-title {
    font-size: 18px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
  }

  .item-content {
    height: 166px;
    overflow: hidden;
    // background: url(https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Base/software/0.jpg);
  }

  .content-cover {
    width: 100%;
  }

  .item-num {
    width: 80px;
    height: 80px;
    background: #FF93B5;
    border-radius: 0px 0px 12px 0px;
    position: absolute;
    top: 0;
    left: 0;
    color: #FFFFFF;
    font-size: 18px;
    text-align: center;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    line-height: 80px; 
  }

  .item-more {
    width: 50px;
    height: 50px;
    background: #7C5FD7;
    border-radius: 25px;
    color: #FFFFFF;
    font-size: 25px;
    text-align: center;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    line-height: 50px;
    position: absolute;
    right: 25px;
    bottom: 10px;
    cursor: pointer; 
  }

  .item-content-detail {
    display: flex;
    justify-content: space-around;
    align-items: center; 
    padding-top: 20px;
    .item-image {
      width: 40%;
      display: flex;
      justify-content: center;
    }

    .item-gallery {
      width: 55%;
    }
  }

  .gallery-swiper-container {
    width: 100%;
    position: relative;

    .gallery-swiper-wrapper {
      height: 100%;
      width: 100%;

      .gallery-swiper-slide {
        color: #ffffff;
        // margin-right: 20px;
        cursor: pointer;
        width: 150px;

        img {
          width: 100%;
          cursor: pointer;
          // height: 100%;
        }
      }
    }

    .swiper-mask {
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
      height: 100%;
      width: 100%;
      z-index: 999;
      pointer-events: none;
    }
  }

  .pages-icon {
    width: 20%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
  }

  .page-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    margin: 10px 7px;
  }

  .page-circle:hover {
    cursor: pointer;
  }

  .selected-circle {
    background: #FFFFFF;
  }

  .arrow-box {
    margin: 0 auto;
    width: 12%;
    display: flex;
    justify-content: center;
  }

  .archives-arrow {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    line-height: 40px;
    color: #FFFFFF;
    line-height: 38px;
    text-align: center;
    cursor: pointer
  }

  .archives-next {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }

  .archives-prev {
    transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
  }
</style>